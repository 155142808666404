<template>
  <div class="full-height full-width d-flex">
    <div
      :style="{ width: `${size}px` }"
      class="d-flex ant-glass-background ant-border-right radius-0 full-height"
    >
      <dhme-wkb-inspection-plan-risks class="flex-1" />
      <resize-horizontal @size="setSize" />
    </div>
    <dhme-wkb-inspection-plan-risks-detail />
  </div>
</template>

<script>
import ResizeHorizontal from '@/components/ResizeHorizontal.vue';
import DhmeWkbInspectionPlanRisks from '@/modules/daiwa-house-modular-europe/WkbInspectionPlan/DhmeWkbInspectionPlanRisks.vue';
import DhmeWkbInspectionPlanRisksDetail from '@/modules/daiwa-house-modular-europe/WkbInspectionPlan/DhmeWkbInspectionPlanRisksDetail.vue';

export default {
  name: 'DhmeWkbInspectionPlanOverview',
  components: {
    DhmeWkbInspectionPlanRisksDetail,
    DhmeWkbInspectionPlanRisks,

    ResizeHorizontal,
  },
  data: () => {
    return {
      size: 0,
    };
  },
  mounted() {
    this.size = window.innerWidth / 2;
  },
  methods: {
    setSize(size) {
      this.size = size;
    },
  },
};
</script>

<style scoped></style>
